import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppSnackbarComponent } from './app-snackbar.component';

export type SnackBarMultiLineData = {
  title: string;
  messages: string[];
};

export enum SnackBarMessageTypeEnum {
  success = 'snack-bar-success',
  error = 'snack-bar-error',
  info = 'snack-bar-info',
}

@Injectable({
  providedIn: 'root',
})
export class AppSnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  public openSnackBar(
    message: string,
    messageType: SnackBarMessageTypeEnum,
    duration = 3000
  ) {
    this.snackBar.open(message, 'X', {
      duration: duration,
      panelClass: [this.getClassByType(messageType)],
      horizontalPosition: 'right',
      verticalPosition: 'top',
    });
  }

  public openSnackBarMultiLine(
    title: string,
    messages: string[],
    messageType: SnackBarMessageTypeEnum
  ) {
    this.snackBar.openFromComponent(AppSnackbarComponent, {
      data: {
        title,
        messages,
      },
      duration: 5000 + messages.length * 1000,
      panelClass: [this.getClassByType(messageType)],
      horizontalPosition: 'right',
      verticalPosition: 'top',
    });
  }

  private getClassByType(type: SnackBarMessageTypeEnum): string {
    switch (type) {
      case SnackBarMessageTypeEnum.success:
        return 'success-snackbar';
      case SnackBarMessageTypeEnum.error:
        return 'error-snackbar';
      case SnackBarMessageTypeEnum.info:
      default:
        return 'info-snackbar';
    }
  }
}
