/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
  {
    id: 'sellers',
    title: 'Vendedores',
    type: 'basic',
    icon: 'mat_outline:supervised_user_circle',
    link: '/vendedores',
  },
  {
    id: 'deposits',
    title: 'Depósitos',
    type: 'basic',
    icon: 'heroicons_outline:banknotes',
    link: '/depositos',
  },
  {
    id: 'campanhas',
    title: 'Campanhas',
    type: 'basic',
    icon: 'heroicons_outline:rocket-launch',
    link: '/campanhas',
  },
  {
    id: 'jackpots',
    title: 'Jackpots',
    type: 'basic',
    icon: 'heroicons_outline:ticket',
    link: '/jackpots',
  },
];
export const compactNavigation: FuseNavigationItem[] = [...defaultNavigation];
export const futuristicNavigation: FuseNavigationItem[] = [
  ...defaultNavigation,
];
export const horizontalNavigation: FuseNavigationItem[] = [
  ...defaultNavigation,
];
