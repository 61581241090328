import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
  // Redirect empty path to '/example'
  { path: '', pathMatch: 'full', redirectTo: '/vendedores' },

  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: '/vendedores' },

  // Auth routes for guests
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'sign-in',
        loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes'),
      },
    ],
  },

  // Auth routes for authenticated users
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'sair',
        loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes'),
      },
    ],
  },

  // // Landing routes
  // {
  //   path: '',
  //   component: LayoutComponent,
  //   data: {
  //     layout: 'empty',
  //   },
  //   children: [
  //     {
  //       path: 'home',
  //       loadChildren: () => import('app/modules/landing/home/home.routes'),
  //     },
  //   ],
  // },

  // Admin routes
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: initialDataResolver,
    },
    children: [
      {
        path: 'vendedores',
        loadChildren: () => import('app/modules/admin/sellers/sellers.routes'),
      },
      {
        path: 'depositos',
        loadChildren: () =>
          import('app/modules/admin/deposits/deposits.routes'),
      },
      {
        path: 'campanhas',
        loadChildren: () =>
          import('app/modules/admin/campaigns/campaigns.routes'),
      },
      {
        path: 'jackpots',
        loadChildren: () =>
          import('app/modules/admin/jackpots/jackpots.routes'),
      },
    ],
  },
];
