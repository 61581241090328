<span class="app-snackbar-multiline" matSnackBarLabel>
  <div *ngIf="data.title">
    <b>{{ data.title }}</b>
    <br />
  </div>
  <ul>
    <li *ngFor="let msg of data.messages">{{ msg }}</li>
  </ul>
</span>
<span matSnackBarActions>
  <button
    class="w-10 h-10"
    mat-icon-button
    matSnackBarAction
    (click)="snackBarRef.dismissWithAction()">
    X
  </button>
</span>
