<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<ng-container *ngIf="isScreenSmall">
  <fuse-vertical-navigation
    class="dark bg-gray-900 print:hidden"
    [mode]="'over'"
    [name]="'mainNavigation'"
    [navigation]="navigation.default"
    [opened]="false">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
      <!-- Logo -->
      <div class="flex items-center h-20 pt-6 px-8">
        <!--        <img-->
        <!--          class="w-24"-->
        <!--          src="assets/images/logo/logo-text-on-dark.svg"-->
        <!--          alt="Logo image" />-->
        <logo classSize="w-18"></logo>
      </div>
    </ng-container>
  </fuse-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div
  class="flex flex-col flex-auto items-center w-full min-w-0 bg-gray-200 dark:bg-card">
  <!-- Header -->
  <div
    class="relative flex flex-col flex-0 justify-center w-full h-16 sm:h-20 md:h-36 overflow-hidden z-49 shadow dark:shadow-none print:hidden">
    <!-- Top bar -->
    <div
      class="relative dark flex flex-auto justify-center w-full px-4 md:px-8 bg-gray-800 dark:bg-gray-900">
      <div class="flex items-center w-full max-w-360 h-16 sm:h-20">
        <!-- Logo -->
        <ng-container *ngIf="!isScreenSmall">
          <div class="flex items-center">
            <!--                        <img-->
            <!--                            class="w-24"-->
            <!--                            src="assets/images/logo/logo-text-on-dark.svg"-->
            <!--                            alt="Logo image">-->
            <logo></logo>
          </div>
        </ng-container>
        <div class="flex items-center w-full p-4 pl-6" *ngIf="devlopmentMode">
          <fuse-alert
            showIcon="true"
            [type]="'warning'"
            [appearance]="'outline'"
            class="w-full">
            <span fuseAlertTitle>DEVELOPMENT MODE</span>
          </fuse-alert>
        </div>
        <!-- Navigation toggle button -->
        <ng-container *ngIf="isScreenSmall">
          <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
          </button>
        </ng-container>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
          <!--                    <languages></languages>-->
          <!--          <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>-->
          <!--          <search [appearance]="'bar'"></search>-->
          <!--                    <shortcuts></shortcuts>-->
          <!--                    <messages></messages>-->
          <!--                    <notifications></notifications>-->
          <!--                    <button-->
          <!--                        class="lg:hidden"-->
          <!--                        mat-icon-button-->
          <!--                        (click)="quickChat.toggle()">-->
          <!--                        <mat-icon [svgIcon]="'heroicons_outline:chat-bubble-left-right'"></mat-icon>-->
          <!--                    </button>-->
          <user></user>
        </div>
      </div>
    </div>
    <!-- Bottom bar -->
    <ng-container *ngIf="!isScreenSmall">
      <div
        class="flex flex-auto justify-center px-4 md:px-8 bg-card dark:bg-gray-700">
        <div class="relative flex items-center w-full max-w-360 h-16">
          <fuse-horizontal-navigation
            class="-mx-4"
            [name]="'mainNavigation'"
            [navigation]="navigation.horizontal"></fuse-horizontal-navigation>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- Content -->
  <div class="flex flex-auto justify-center w-full sm:p-6 md:p-8">
    <div
      class="flex flex-col flex-auto w-full sm:max-w-360 sm:shadow-lg sm:rounded-lg sm:overflow-hidden bg-default">
      <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
                                               Otherwise, layout changes won't be registered and the view won't be updated! -->
      <router-outlet *ngIf="true"></router-outlet>
    </div>
  </div>

  <!-- Footer -->
  <div
    class="relative flex flex-0 justify-center items-center w-full px-6 md:px-8 z-49 border-t bg-card print:hidden">
    <div
      class="flex justify-between items-center w-full max-w-360 h-10 sm:h-14">
      <div class="flex items-center">
        <img
          class="h-6 sm:h-8 w-auto"
          src="/assets/images/logo/logomdotech.png"
          alt="MdoTech" />
        <span class="font-medium text-secondary">
          &copy; {{ currentYear }}</span
        >
      </div>

      <small class="text-secondary text-nowrap">V: {{ version }}</small>
    </div>
  </div>
</div>

<!-- Quick chat -->
<!--<quick-chat #quickChat="quickChat"></quick-chat>-->
