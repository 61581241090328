import meta from '../../package.json';
import { Environment } from './env.type';

export const baseEnvironment: Environment = {
  envName: 'base',
  graphqlUrl: 'https://api.dev.mdopix.com.br/graphql',
  clientName: 'Admin MDO Pix',
  clientVersion: meta.version,
  clientPlatform: 'Web',
  language: 'pt-br',
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  firebase: {
    apiKey: 'AIzaSyBoucXPiTTYvSorH8X83xmZ2HwzFx8XVnM',
    authDomain: 'mdo-pix.firebaseapp.com',
    projectId: 'mdo-pix',
    storageBucket: 'mdo-pix.appspot.com',
    messagingSenderId: '659372584860',
    appId: '1:659372584860:web:ab8320540ef6da30502259',
    measurementId: 'G-SDEQLRF9VS',
  },
};
