import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable, ReplaySubject, tap } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { User } from '../../../../graphql/generated';

@Injectable({ providedIn: 'root' })
export class UserService {
  private _httpClient = inject(HttpClient);
  private _apolloService = inject(Apollo);
  private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set user(value: User) {
    // Store the value
    this._user.next(value);
  }

  get user$(): Observable<User> {
    return this._user.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the current logged in user data : Observable<User>
   */
  public getMyUser() {
    const MY_USER = gql`
      query MyUser {
        myUser {
          user {
            id
            username
            status
            roles
            deletedAt {
              transform(format: "DD-MM-YYYY")
            }
          }
        }
      }
    `;
    this._apolloService
      .watchQuery({
        query: MY_USER,
      })
      .valueChanges.subscribe((response: any) => {
        this._user.next(response.data.myUser.user);
      });
  }

  /**
   * Get the current signed-in user data
   */
  get(): Observable<User> {
    return this._httpClient.get<User>('api/common/user').pipe(
      tap(user => {
        this._user.next(user);
      })
    );
  }

  /**
   * Update the user
   *
   * @param user
   */
  update(user: User): Observable<any> {
    return this._httpClient.patch<User>('api/common/user', { user }).pipe(
      map(response => {
        this._user.next(response);
      })
    );
  }
}
